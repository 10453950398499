<template lang="html">
  <div class="container pt-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'Index' }">Панель</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Лейблы
        </li>
      </ol>
    </nav>
    <div class="d-flex mb-3">
      <h3>Лейблы</h3>
      <button
        class="btn btn-light ms-auto"
        data-bs-toggle="modal"
        data-bs-target="#labelModal"
        @click="$store.commit('mergeStore', {label: {}})">
        Добавить
      </button>
    </div>

    <div class="table-responsive">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Название</th>
            <th>Варианты</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="label in $store.state.labels"
            :key="label.id">
            <td>
              <div class="d-flex align-items-center">
                {{ label.name }}
                <div class="dropdown" v-if="label.chats.length">
                  <button class="btn btn-link" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-square-dots" viewBox="0 0 16 16">
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                      <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                    </svg>
                  </button>
                  <ul class="dropdown-menu">
                    <li v-for="chat in label.chats" :key="chat.id">
                      <router-link
                        class="dropdown-item"
                        :to="{ name: 'Chat', params: {id: chat.id} }">
                        {{ chat.name || chat.telegram_name || 'Чат' }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex flex-wrap">
                <div
                  v-for="(variant, i) in label.variants"
                  class=" me-2"
                  :key="i">
                  {{ variant }}
                  <button
                    class="btn btn-sm btn-link"
                    @click="deleteVariant(label, i)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                      <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </td>
            <td class="text-end">
              <div class="btn-group">
                <button
                  class="btn btn-secondary btn-sm"
                  @click="$store.commit('mergeStore', { label })"
                  data-bs-toggle="modal"
                  data-bs-target="#labelModal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info" viewBox="0 0 16 16">
                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                  </svg>
                </button>
                <button
                  class="btn btn-danger btn-sm"
                  @click="deleteLabel(label)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      class="modal fade"
      id="labelModal"
      ref="labelModal"
      tabindex="-1"
      aria-labelledby="titleLabelModal"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="titleLabelModal">Лейбл</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="mb-4">
              <label class="form-label">Название</label>
              <input
                type="text"
                v-model="$store.state.label.name"
                class="form-control"
                placeholder="Название">
            </div>

            <div class="d-flex mb-3 flex-wrap" v-if="$store.state.label.id">
              <div
                v-for="(variant, i) in $store.state.label.variants"
                class="bg-secondary ps-2 rounded me-2 mb-2 d-flex align-items-center"
                :key="i">
                {{ variant }}
                <button
                  class="btn btn-secondary btn-sm ms-1"
                  @click="deleteVariant($store.state.label, i)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </button>
              </div>
            </div>

            <div class="d-flex" v-if="$store.state.label.id">
              <input
                type="text"
                v-model="variant"
                class="form-control me-2"
                placeholder="Вариант">
              <span class="btn btn-success btn-sm px-5" @click="addVariant">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send-fill" viewBox="0 0 16 16">
                  <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
                </svg>
              </span>
            </div>

          </div>
          <div class="modal-footer">
            <button class="btn btn-primary me-3" @click="$socket.emit('labels', $store.state.label)">Сохранить</button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Modal } from 'bootstrap'
export default {
  name: 'Labels',

  data () {
    return {
      variant: null,
    }
  },

  methods: {
    deleteLabel (label) {
      if (confirm('Удалить лейбл?')) {
        this.$socket.emit('delete_label', label)
      }
    },
    deleteVariant (label, i) {
      // const variants = [ ...this.$store.state.label.variants ]
      label.variants.splice(i, 1)
      this.$socket.emit('labels', label)
    },
    addVariant () {
      if (!this.variant) {
        return this.$toast.error('Вариант не может быть пустым')
      }
      if (this.variant.length < 3) {
        return this.$toast.error('Вариант должен быть больше двух символов')
      }
      if (this.$store.state.label.variants.includes(this.variant)) {
        return this.$toast.error('Вариант уже присутствует в лейбле')
      }
      const variants = [ ...this.$store.state.label.variants ]
      variants.push(this.variant)
      this.$socket.emit('labels', {
        ...this.$store.state.label,
        variants
      })
      this.variant = null
    },
  },

  async mounted () {
    try {
      const labelModal = new Modal(this.$refs.labelModal)
      this.$store.commit('mergeStore', { labelModal })
      this.$socket.emit('get_data', ['labels'])
    } catch (e) {
      console.error(e)
      this.$toast.error(`${e.type}: ${e.message}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.card{
  .btn{
    display: none;
  }
  &:hover{
    .btn{
      display: inline-block;
    }
  }
}
</style>
